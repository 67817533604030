.authorizationPage {
    background-color: #079DAC;
    width: 100vw;
    height: 100vh;
}

.authorizationForm {
    margin-left: 160px;
    margin-top: 170px;
}

.authorizationForm h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 16px;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.form {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
}

.form input {
    border: 1px solid #FFFFFF;
    caret-color: #FFFFFF;
    color: #FFFFFF;
    border-radius: 5px;
    width: 300px;
    height: 40px;
    outline: none;
    padding: 12px 13px;
    margin-bottom: 10px;
    background-color: transparent;
}

.authorizationForm .formButton {
    width: 134px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-top: 5px;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #106386;
}

.logo {
    margin-left: 160px;
    margin-top: 20px;
    cursor: pointer;
}

input.redBorders {
    border: 1px solid #cb3c3c;
}