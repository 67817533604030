.typesBlock {
    padding: 92px 0 0 16px;
}

.typesList::-webkit-scrollbar { 
    display: none;
}

.typesList {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    max-height: 480px;
}

.typesBlock h1 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #2C2C2C;
    margin-bottom: 20px;
}

.typesBlock {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #333333;
}
