.dashBoard {
    width: 100%;
    height: 100vh;
}

.infoTable {
    display: flex;
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.infoTable::-webkit-scrollbar { 
    display: none;
}