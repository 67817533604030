@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&family=Roboto:wght@100;300;500;700;900&display=swap');

.groupContainer {
    padding: 112px 0 20px 108px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    height: 100vh;
    margin-top: -20px;
}

.groupContainer::-webkit-scrollbar { 
    display: none;
}

.arrow {
    cursor: pointer;
    position: absolute;
    margin-top: 90px;
    margin-left: 32px;
}

.dots {
    margin-top: 70px;
    display: flex;
}

.dots button {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #2F80ED;
    cursor: pointer;
    border: none;
    margin-right: 10px;
    background: none;
}

button.active {
    background: #079DAC;
    color: #fff;
}

.flexSelect {
    display: flex;
    margin-top: 20px;
}

.selectProperty {
    margin-right: 40px;
}

.selectProperty h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #2C2C2C;
    margin-bottom: 10px;
}

.total {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #000000;
    margin-top: 18px;
    margin-bottom: 10px;
}

.tableGroup {
    max-height: 400px;
}