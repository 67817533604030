@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&family=Roboto:wght@100;300;500;700;900&display=swap');

.modalCard {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(44, 44, 44, 0.7);
    padding: 16px 58px;
}

.card {
    background-color: #fff;
    border-radius: 8px;
    height: 100%;
    max-height: 780px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 26px 40px 36px 22px;
}

.card::-webkit-scrollbar { 
    display: none;
}

.cancelBtn {
    background: rgba(44, 44, 44, 0.5);
    border-radius: 4px;
    height: 40px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
    outline: none;
    cursor: pointer;
    border: none;
}

.saveBtn {
    height: 40px;
    width: 276px;
    outline: none;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2C2C2C;
    border-radius: 4px;
    margin-right: 30px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}

.buttons {
    margin-top: 120px;
    display: flex;
    justify-content: end;
}

.hide {
    display: none;
}

.cardHeader {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #2C2C2C;
}

.selectBoxes {
    margin-top: 20px;
    margin-left: 30px;
    margin-bottom: 20px;
}

.selectBox {
    margin-top: 10px;
    width: 276px;
}

.selectBox h2, .description h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #2C2C2C;
    margin-bottom: 8px;
}

.cardInput {
    background: #FFFFFF;
    border: 1px solid #079DAC;
    border-radius: 8px;
    width: 276px;
    height: 40px;
    color: #333333;
    outline: none;
    caret-color: #079DAC;
    padding: 0 17px;
}

input::placeholder, textarea::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #838D96;
}

.additionalFields {
    margin-top: 20px;
    margin-left: 30px;
    display: flex;
}

.additionalFields .selectBox {
    margin-right: 54px;
    margin-top: 0;
}

.description {
    margin-top: 20px;
    margin-left: 30px;
}

.description .cardInput {
    width: 100%;
    height: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    resize: none;
}
