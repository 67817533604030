.tableRow {
    display: flex;
    height: 40px;
}

.header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #333333;
}

.commonRow {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #333333;
}