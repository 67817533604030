.select {
    background: #FFFFFF;
    border: 1px solid #079DAC;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #838D96;
    padding: 0 17px;
    height: 40px;
    width: 276px;
    background-image: url("../../resources/img/arrowSelect.png");
    background-repeat: no-repeat;
    background-position-x: 244px;
    background-position-y: 10px;
    display: flex;
    align-items: center;
}

.hideOptions {
    display: none;
}

.showOptions {
    display: block;
    position: absolute;
    border-radius: 8px;
    margin-top: -1px;
    background: #FFFFFF;
    border: 1px solid #079DAC;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    max-height: 320px;
}

.showOptions::-webkit-scrollbar { 
    display: none;
}

.showOptions div {
    cursor: pointer;
    border-radius: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #2C2C2C;
    padding: 0 17px;
    height: 40px;
    width: 274px;
    display: flex;
    align-items: center;
}

.showOptions div:hover {
    background: #079DAC;
    color: #fff;
}