ul.searchResult {
    position: absolute;
    z-index: 2;
    margin-top: 60px;
    margin-left: 20px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    cursor: pointer;
}

ul.searchResult li {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 14px 12px 16px 12px;
    background: #fff;
}

.flexBox {
    display: flex;
    margin-left: 12px;
}

.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-right: 10px;
}

.titleValue {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.searchResultHeader {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    margin-left: 12px;
}

.lineSearch {
    width: 100%;
    height: 0px;
    border: 1px solid #000000;
}

.searchResult {
    height: 500px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.searchResult::-webkit-scrollbar { 
    display: none;
}
